import React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import logo from '../assets/VolitareStudiosLogo.png';

const Navbar = () => {
  return (
    <AppBar position="static" sx={{backgroundColor: '#000'}}>
      <Toolbar>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center'}}>
          <img src={logo} alt="Logo" style={{ width: '50px', height: '50px', borderRadius: '50%', marginRight: '20px' }} />
          <Typography variant="h6" sx={{ flexGrow: 1, fontFamily: 'Baskic8', fontWeight: 'bold', lineHeight: '1', marginTop: '15px' }}>
            Volitare Studios
          </Typography>
        </Box>
        <Button color="inherit" component={Link} to="/" sx={{ color: 'white', fontFamily: 'Baskic8', fontWeight: 'bold', paddingTop: '16px' }}>Home</Button>
        <Button color="inherit" component={Link} to="/about" sx={{ color: 'white', fontFamily: 'Baskic8', fontWeight: 'bold', paddingTop: '16px' }}>About</Button>
        <Button color="inherit" component={Link} to="/products" sx={{ color: 'white', fontFamily: 'Baskic8', fontWeight: 'bold', paddingTop: '16px' }}>Products</Button>
        <Button color="inherit" component={Link} to="/careers" sx={{ color: 'white', fontFamily: 'Baskic8', fontWeight: 'bold', paddingTop: '16px' }}>Careers</Button>
        <Button color="inherit" component={Link} to="/contact" sx={{ color: 'white', fontFamily: 'Baskic8', fontWeight: 'bold', paddingTop: '16px' }}>Contact</Button>
        <Button color="inherit" component={Link} to="/blog" sx={{ color: 'white', fontFamily: 'Baskic8', fontWeight: 'bold', paddingTop: '16px' }}>Blog</Button>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
