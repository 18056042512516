import React, {useState} from 'react';
import { motion } from 'framer-motion';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Box, Typography, Button } from '@mui/material';
// import bannerTitle from '../assets/example_banner_title.png';
import NewsletterForm from '../components/NewsletterForm';
import bannerGif from '../assets/banner.gif';
// import asteroid from '../assets/asteroid.gif';
// import chess from '../assets/chess.gif';
// import rts from '../assets/rts.gif';
// import shmup from '../assets/shmup.gif';
// import space from '../assets/space.gif';
// import turn_based from '../assets/turn-based-rpg.gif';
import lock from '../assets/lock.png';
import './styles/carousel.css';
import './styles/gameImage.css';
import backgroundImage from '../assets/nebula4k.jpg';

const BannerEntry = ({ gameName, promoText, buttonText, imgSrc }) => (
  <div style={{ position: 'relative' }}>
    <img src={imgSrc} alt={gameName} style={{ width: '100%' }} />
    <div 
      style={{ 
        position: 'absolute', 
        top: 0, 
        left: 0, 
        height: '100%', 
        width: '40%', 
        backgroundColor: 'rgba(0, 0, 0, 0)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          width: '80%', // adjust as needed
          height: '84%', // adjust as needed
          color: 'white',
          backgroundColor: 'rgba(0, 0, 0, 0)',
          padding: '10px',
          borderRadius: '25px',
        }}
      >
        <Typography variant="h3" sx={{textShadow:'2px 2px 8px rgba(0, 0, 0, 0.9)', fontFamily: 'Baskic8', fontWeight: 'bold' }}>{gameName}</Typography>
        <Typography variant="h5" sx={{textShadow:'2px 2px 8px rgba(0, 0, 0, 0.9)', fontFamily: 'Baskic8', fontWeight: 'bold' }}>{promoText}</Typography>
        <Button variant="contained" color="primary" sx={{ 
            mt: 2,
            fontSize: '1.25rem', // Bigger text
            paddingLeft: '30px',
            paddingRight: '30px',
            paddingTop: '20px',
            borderRadius: '20px', // More rounded corners
            fontFamily: 'Baskic8', // Custom font
            fontWeight: 'bold',
            backgroundColor: '#CB905A',
            textAlign: 'center',
         }}>{buttonText}</Button>
      </Box>
    </div>
  </div>
);

const GameImage = ({ src, alt, buttonText = 'Discover', overlayText = 'Lorem ipsum dolor sit amet' }) => {
  const [hover, setHover] = useState(false);

  return (
    <div 
      className="game-image-container" 
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <img src={src} alt={alt} className="game-image"/>
      <div className={`overlay ${hover ? 'hover' : ''}`}>
        <h1 className="game-title">{alt}</h1>
        <p className="overlay-text">{overlayText}</p>
        <Button variant="contained" color="primary" sx={{
          marginTop: '1em', /* Base margin */
          fontSize: '1vw', /* Use viewport width units for responsive font size */
          padding: '0.5em 1em', /* Base padding */
          borderRadius: '20px',
          fontFamily: 'Baskic8',
          fontWeight: 'bold',
          backgroundColor: '#CB905A',
          textAlign: 'center',
        }}>
          {buttonText}
        </Button>
      </div>
    </div>
  );
};

const Home = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'space-between',
        minHeight: '100vh', // This makes sure the content takes up at least the full height of the viewport
        alignItems: 'center',
        // color: 'white',
        overflowX: 'hidden',
      }}
    >
      {/* <h1>Home Page</h1>
      <p>Welcome to Indie Studio!</p> */}
      <Carousel
        showArrows={true}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={5000}
        dynamicHeight={false}
      >
        <BannerEntry gameName="GameVolt" promoText="Collect and play a wide range of cartridges in a virtual console!" buttonText="Discover" imgSrc={bannerGif} />
        {/* <BannerEntry gameName="Game 2" promoText="Insert promotional text here!" buttonText="Play Game 2" imgSrc={bannerGif} /> */}
      </Carousel>
      <Box sx={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        padding: '30px 0',
      }}>
        <Typography variant="h4" align="center" gutterBottom sx={{color: "white", fontFamily: 'Baskic8', fontWeight: 'bold' }}>
            Our Games
        </Typography>
        <Carousel
          showArrows={true}
          showThumbs={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={5000}
          dynamicHeight={false}
          centerMode={true}
          centerSlidePercentage={20} // Adjust this percentage to fit the number of items per slide
          emulateTouch={true}
        >
          <GameImage src={lock} alt="Game 1" overlayText='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.' />
          <GameImage src={lock} alt="Game 2" overlayText='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.' />
          <GameImage src={lock} alt="Game 3" overlayText='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.' />
          <GameImage src={lock} alt="Game 4" overlayText='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.' />
          <GameImage src={lock} alt="Game 5" overlayText='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.' />
          <GameImage src={lock} alt="Game 6" overlayText='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.' />
        </Carousel>
      </Box>
      <NewsletterForm />
    </motion.div>
  );
};

export default Home;
