// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';
import { Typography, Link, Button } from '@mui/material';
import Cookies from 'js-cookie';
import './styles/CookieConsent.css'; // Import your CSS for styling

const CookieConsent = () => {
  const [showConsent, setShowConsent] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted the cookie policy
    const consent = Cookies.get('cookie_consent');
    if (!consent) {
      setShowConsent(true);
    }
  }, []);

  const handleAccept = () => {
    // Set a cookie to remember the user's consent
    Cookies.set('cookie_consent', 'true', { expires: 365 });
    setShowConsent(false);
  };

  if (!showConsent) {
    return null;
  }

  return (
    <div className="cookie-consent">
      <Typography sx={{padding:'10px', fontFamily:'Baskic8'}}>We use cookies to ensure the best experience. By using our site, you agree to our <Link href="/privacy-policy" color="inherit">privacy policy</Link>.</Typography>
      <Button onClick={handleAccept} sx={{fontFamily:'Baskic8'}}>Accept</Button>
    </div>
  );
};

export default CookieConsent;
