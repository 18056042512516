import React from 'react';
import { motion } from 'framer-motion';
import './styles/Careers.css';
import backgroundImage from '../assets/nebula4k.jpg';

const Products = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh', // This makes sure the content takes up at least the full height of the viewport
        alignItems: 'center',
        color: 'white',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        // backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F55%2Fe2%2Fb2%2F55e2b2171897530365e1d28ed4f5bbd9.png&f=1&nofb=1&ipt=c9b1c631861c240f22201fcb7b9ca90b2abca85bfc3e945e88db237f0aa04130&ipo=images")', /* Replace with your image path */
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1, // This makes the div take up all available space, pushing the footer to the bottom
      }}>
        <h1 style={{fontSize: '2em', textAlign: 'center', fontFamily: 'Baskic8' }}>Games</h1>
        <p style={{fontSize: '1.5em', textAlign: 'center', fontFamily: 'Baskic8' }}>This page is currently undergoing construction!
          <br></br>Come back soon to see the new changes.</p>
      </div>
    </motion.div>
  );
};

export default Products;
