import React from 'react';
import { motion } from 'framer-motion';
import backgroundImage from '../assets/nebula4k.jpg';

const About = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100vh', // This makes sure the content takes up at least the full height of the viewport
        alignItems: 'center',
        color: 'white',
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
        // backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fi.pinimg.com%2Foriginals%2F55%2Fe2%2Fb2%2F55e2b2171897530365e1d28ed4f5bbd9.png&f=1&nofb=1&ipt=c9b1c631861c240f22201fcb7b9ca90b2abca85bfc3e945e88db237f0aa04130&ipo=images")', /* Replace with your image path */
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1, // This makes the div take up all available space, pushing the footer to the bottom
      }}>
        <h1 style={{fontSize: '2em', textAlign: 'center', fontFamily: 'Baskic8' }}>Origins</h1>
        <p style={{fontSize: '1.5em', textAlign: 'center', fontFamily: 'Baskic8', lineHeight: '1.75' }}>We are an indie game studio based in California with a love for strategy and retro games!
          <br></br>Our story began in December 2019 when we started developing our first internal prototypes
          <br></br>and systems before formally being founded as a bootstrapped startup on February 10, 2024.
          <br></br>
          <br></br>We are a small team of passionate developers and designers who are currently busy at work
          <br></br>on our first title, GameVolt.
          <br></br>
          <br></br>GameVolt will serve as a foundation where we can test and refine mechanics for future
          <br></br>projects, and we are excited to share more about our progress in the coming months.
          <br></br>Stay tuned for more updates!</p>
      </div>
    </motion.div>
  );
};

export default About;

/*
We are an indie game studio based in California with a love for strategy and retro games! 
Our story began in December 2019 when we started developing our first internal prototypes 
and systems before formally being founded as a bootstrapped startup on February 10, 2024. 
We are a small team of passionate developers and designers who are currently busy at work 
on our first title, GameVolt.

GameVolt will be serving as a foundation where we can test and refine mechanics for future
projects, and we are excited to share more about our progress in the coming months. 
Stay tuned for more updates!
*/