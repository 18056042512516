import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import ReactMarkdown from 'react-markdown';
import { Box, Container } from '@mui/material';
import './styles/privacy.css';
import './styles/scrollbars.css';

const Privacy = () => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch('./documents/PrivacyPolicy.md')
          .then((response) => {
            if (response.ok) {
              return response.text();
            }
            throw new Error('Network response was not ok.');
          })
          .then((text) => setContent(text))
          .catch((error) => console.error('Fetch error:', error));
      }, []);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='privacy-container'
    >
     <div className="overlay"></div> {/* Dark overlay */}
     <Container maxWidth="md" className="content-container">
        <Box sx={{ padding: '20px', backgroundColor: 'white', color: 'black' }}>
      <ReactMarkdown>{content}</ReactMarkdown>
      </Box>
      </Container>
    </motion.div>
  );
};

export default Privacy;
