// NewsletterForm.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Link } from '@mui/material';
import Cookies from 'js-cookie';
// import { supabase } from '../services/supabaseClient';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const savedEmail = Cookies.get('subscribed_email');
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateEmail(email)) {
      setMessage('Please enter a valid email address');
      return;
    }

    // // eslint-disable-next-line no-unused-vars
    // const { data, error } = await supabase
    //   .from('subscriptions')
    //   .insert([{ email }]);

    // if (error) {
    //   setMessage('There was an error, please try again.');
    // } else {
    //   setMessage('Thank you for subscribing!');
    //   Cookies.set('subscribed_email', email, { expires: 7 });
    //   setEmail('');
    // }

    try {
      console.log('email:', email);
      const response = await fetch('https://volitarerpiprod.ddns.net/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      // const result = await response.json();
      if (response.ok) {
        setMessage('Thank you for subscribing!');
        Cookies.set('subscribed_email', email, { expires: 365 });
        setEmail('');
      } else {
        setMessage('There was an error, please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('There was an error, please try again.');
    }
  };

  const unsubscribe = async (email) => {
    const savedEmail = Cookies.get('subscribed_email');
    if (!savedEmail) {
      setMessage('No subscribed email found.');
      return;
    }

    // Handle unsubscribe logic here

    setMessage('You have been unsubscribed.');
    Cookies.remove('subscribed_email');

    // // eslint-disable-next-line no-unused-vars
    // const { data, error } = await supabase
    //   .from('subscriptions')
    //   .delete()
    //   .eq('email', email);
    // if (error) {
    //   setMessage('There was an error, please try again.');
    // } else {
    //   setMessage('You have been unsubscribed.');
    //   Cookies.remove('subscribed_email');
    // }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  return (
    <Box sx={{ 
      textAlign: 'center', 
      // mt: 5,
      // backgroundImage: `url(${""})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      padding: '30px 30px',
      width: '100%',

    }}>
      <Typography variant="h4" sx={{ color: 'white', fontFamily: 'Baskic8' }}>Be the first to hear about new releases and offers!</Typography>
      <Typography variant="h6" sx={{ color: 'white', mb: 3, fontFamily: 'Baskic8' }}>
        Sign up to our newsletter for news, offers, and invitations to closed playtests!
      </Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          type="email"
          variant="outlined"
          placeholder="Enter your email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ width: '400px', mb: 2, backgroundColor: 'white', borderRadius: '10px', fontFamily: 'Baskic8' }}
        />
        <br />

      <Typography variant="body2" sx={{ color: 'white', mb: 2, fontFamily: 'Baskic8' }}>
        You can <Link color="inherit" onClick={unsubscribe} >unsubscribe</Link> at any time. Here is our <Link href="/privacy-policy" color="inherit">privacy policy</Link>.
      </Typography>

        <Button 
        variant="contained" 
        color="primary" 
        type="submit"
        sx={{
          mt: 2,
          fontSize: '1.25rem', // Bigger text
          // padding: '15px 30px', // Bigger button
          paddingLeft: '30px',
          paddingRight: '30px',
          paddingTop: '20px',
          borderRadius: '20px', // More rounded corners
          fontFamily: 'Baskic8', // Custom font
          fontWeight: 'bold',
          backgroundColor: '#CB905A',
        }}
        >
          Subscribe
        </Button>
      </form>
      {message && <Typography variant="body2" sx={{ color: 'white', mt: 2 }}>{message}</Typography>}
    </Box>
  );
};

export default NewsletterForm;
